/**
 * Removes html from a string using regex. Warning: Doesn't catch all valid html.
 * I.e. <div class="hello>test">hi</div> would be parsed incorrectly as test">hi
 */
import escape from 'lodash/escape'

export function striphtml(text: string, escapetext: boolean = true) {
  const regex = /(<([^>]+)>)/gi

  if (escapetext) {
    return escape(text.replace(regex, ''))
  }

  return text.replace(regex, '')
}
